.MathJax_Hover_Frame {
  border-radius: 0.25em;
  -webkit-border-radius: 0.25em;
  -moz-border-radius: 0.25em;
  -khtml-border-radius: 0.25em;
  box-shadow: 0px 0px 15px #83A;
  -webkit-box-shadow: 0px 0px 15px #83A;
  -moz-box-shadow: 0px 0px 15px #83A;
  -khtml-box-shadow: 0px 0px 15px #83A;
  border: 1px solid #A6D !important;
  display: inline-block;
  position: absolute;
}

.MathJax_Menu_Button .MathJax_Hover_Arrow {
  position: absolute;
  cursor: pointer;
  display: inline-block;
  border: 2px solid #AAA;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  font-family: "Courier New", Courier;
  font-size: 9px;
  color: #F0F0F0;
}

.MathJax_Menu_Button .MathJax_Hover_Arrow span {
  display: block;
  background-color: #AAA;
  border: 1px solid;
  border-radius: 3px;
  line-height: 0;
  padding: 4px;
}

.MathJax_Hover_Arrow:hover {
  color: white !important;
  border: 2px solid #CCC !important;
}

.MathJax_Hover_Arrow:hover span {
  background-color: #CCC !important;
}

#MathJax_About {
  position: fixed;
  left: 50%;
  width: auto;
  text-align: center;
  border: 3px outset;
  padding: 1em 2em;
  background-color: #DDDDDD;
  color: black;
  cursor: default;
  font-family: message-box;
  font-size: 120%;
  font-style: normal;
  text-indent: 0;
  text-transform: none;
  line-height: normal;
  letter-spacing: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  float: none;
  z-index: 201;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -khtml-border-radius: 15px;
  box-shadow: 0px 10px 20px #808080;
  -webkit-box-shadow: 0px 10px 20px #808080;
  -moz-box-shadow: 0px 10px 20px #808080;
  -khtml-box-shadow: 0px 10px 20px #808080;
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color="gray", Positive="true");
}

#MathJax_About.MathJax_MousePost {
  outline: none;
}

.MathJax_Menu {
  position: absolute;
  background-color: white;
  color: black;
  width: auto;
  padding: 2px;
  border: 1px solid #CCCCCC;
  margin: 0;
  cursor: default;
  font: menu;
  text-align: left;
  text-indent: 0;
  text-transform: none;
  line-height: normal;
  letter-spacing: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  float: none;
  z-index: 201;
  box-shadow: 0px 10px 20px #808080;
  -webkit-box-shadow: 0px 10px 20px #808080;
  -moz-box-shadow: 0px 10px 20px #808080;
  -khtml-box-shadow: 0px 10px 20px #808080;
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color="gray", Positive="true");
}

.MathJax_MenuItem {
  padding: 2px 2em;
  background: transparent;
}

.MathJax_MenuArrow {
  position: absolute;
  right: 0.5em;
  padding-top: 0.25em;
  color: #666666;
  font-size: 0.75em;
}

.MathJax_MenuActive .MathJax_MenuArrow {
  color: white;
}

.MathJax_MenuArrow.RTL {
  left: 0.5em;
  right: auto;
}

.MathJax_MenuCheck {
  position: absolute;
  left: 0.7em;
}

.MathJax_MenuCheck.RTL {
  right: 0.7em;
  left: auto;
}

.MathJax_MenuRadioCheck {
  position: absolute;
  left: 1em;
}

.MathJax_MenuRadioCheck.RTL {
  right: 1em;
  left: auto;
}

.MathJax_MenuLabel {
  padding: 2px 2em 4px 1.33em;
  font-style: italic;
}

.MathJax_MenuRule {
  border-top: 1px solid #CCCCCC;
  margin: 4px 1px 0px;
}

.MathJax_MenuDisabled {
  color: GrayText;
}

.MathJax_MenuActive {
  background-color: Highlight;
  color: HighlightText;
}

.MathJax_MenuDisabled:focus,
.MathJax_MenuLabel:focus {
  background-color: #E8E8E8;
}

.MathJax_ContextMenu:focus {
  outline: none;
}

.MathJax_ContextMenu .MathJax_MenuItem:focus {
  outline: none;
}

#MathJax_AboutClose {
  top: 0.2em;
  right: 0.2em;
}

.MathJax_Menu .MathJax_MenuClose {
  top: -10px;
  left: -10px;
}

.MathJax_MenuClose {
  position: absolute;
  cursor: pointer;
  display: inline-block;
  border: 2px solid #AAA;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -khtml-border-radius: 18px;
  font-family: "Courier New", Courier;
  font-size: 24px;
  color: #F0F0F0;
}

.MathJax_MenuClose span {
  display: block;
  background-color: #AAA;
  border: 1.5px solid;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -khtml-border-radius: 18px;
  line-height: 0;
  padding: 8px 0 6px;
}

.MathJax_MenuClose:hover {
  color: white !important;
  border: 2px solid #CCC !important;
}

.MathJax_MenuClose:hover span {
  background-color: #CCC !important;
}

.MathJax_MenuClose:hover:focus {
  outline: none;
}

.MJX_Assistive_MathML {
  position: absolute !important;
  top: 0;
  left: 0;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 1px 0 0 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.MJX_Assistive_MathML.MJX_Assistive_MathML_Block {
  width: 100% !important;
}

#MathJax_Zoom {
  position: absolute;
  background-color: #F0F0F0;
  overflow: auto;
  display: block;
  z-index: 301;
  padding: 0.5em;
  border: 1px solid black;
  margin: 0;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  text-indent: 0;
  text-transform: none;
  line-height: normal;
  letter-spacing: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  float: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  box-shadow: 5px 5px 15px #AAAAAA;
  -webkit-box-shadow: 5px 5px 15px #AAAAAA;
  -moz-box-shadow: 5px 5px 15px #AAAAAA;
  -khtml-box-shadow: 5px 5px 15px #AAAAAA;
  filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=2, OffY=2, Color="gray", Positive="true");
}

#MathJax_ZoomOverlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 300;
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: white;
  opacity: 0;
  filter: alpha(opacity=0);
}

#MathJax_ZoomFrame {
  position: relative;
  display: inline-block;
  height: 0;
  width: 0;
}

#MathJax_ZoomEventTrap {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 302;
  display: inline-block;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: white;
  opacity: 0;
  filter: alpha(opacity=0);
}

.MathJax_Preview {
  color: #888;
}

#MathJax_Message {
  position: fixed;
  left: 1em;
  bottom: 1.5em;
  background-color: #E6E6E6;
  border: 1px solid #959595;
  margin: 0px;
  padding: 2px 8px;
  z-index: 102;
  color: black;
  font-size: 80%;
  width: auto;
  white-space: nowrap;
}

#MathJax_MSIE_Frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  z-index: 101;
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.MathJax_Error {
  color: #CC0000;
  font-style: italic;
}

.MJXp-script {
  font-size: 0.8em;
}

.MJXp-right {
  -webkit-transform-origin: right;
  -moz-transform-origin: right;
  -ms-transform-origin: right;
  -o-transform-origin: right;
  transform-origin: right;
}

.MJXp-bold {
  font-weight: bold;
}

.MJXp-italic {
  font-style: italic;
}

.MJXp-scr {
  font-family: MathJax_Script, "Times New Roman", Times, STIXGeneral, serif;
}

.MJXp-frak {
  font-family: MathJax_Fraktur, "Times New Roman", Times, STIXGeneral, serif;
}

.MJXp-sf {
  font-family: MathJax_SansSerif, "Times New Roman", Times, STIXGeneral, serif;
}

.MJXp-cal {
  font-family: MathJax_Caligraphic, "Times New Roman", Times, STIXGeneral, serif;
}

.MJXp-mono {
  font-family: MathJax_Typewriter, "Times New Roman", Times, STIXGeneral, serif;
}

.MJXp-largeop {
  font-size: 150%;
}

.MJXp-largeop.MJXp-int {
  vertical-align: -0.2em;
}

.MJXp-math {
  display: inline-block;
  line-height: 1.2;
  text-indent: 0;
  font-family: "Times New Roman", Times, STIXGeneral, serif;
  white-space: nowrap;
  border-collapse: collapse;
}

.MJXp-display {
  display: block;
  text-align: center;
  margin: 1em 0;
}

.MJXp-math span {
  display: inline-block;
}

.MJXp-box {
  display: block !important;
  text-align: center;
}

.MJXp-box:after {
  content: " ";
}

.MJXp-rule {
  display: block !important;
  margin-top: 0.1em;
}

.MJXp-char {
  display: block !important;
}

.MJXp-mo {
  margin: 0 0.15em;
}

.MJXp-mfrac {
  margin: 0 0.125em;
  vertical-align: 0.25em;
}

.MJXp-denom {
  display: inline-table !important;
  width: 100%;
}

.MJXp-denom > * {
  display: table-row !important;
}

.MJXp-surd {
  vertical-align: top;
}

.MJXp-surd > * {
  display: block !important;
}

.MJXp-script-box > * {
  display: table !important;
  height: 50%;
}

.MJXp-script-box > * > * {
  display: table-cell !important;
  vertical-align: top;
}

.MJXp-script-box > *:last-child > * {
  vertical-align: bottom;
}

.MJXp-script-box > * > * > * {
  display: block !important;
}

.MJXp-mphantom {
  visibility: hidden;
}

.MJXp-munderover {
  display: inline-table !important;
}

.MJXp-over {
  display: inline-block !important;
  text-align: center;
}

.MJXp-over > * {
  display: block !important;
}

.MJXp-munderover > * {
  display: table-row !important;
}

.MJXp-mtable {
  vertical-align: 0.25em;
  margin: 0 0.125em;
}

.MJXp-mtable > * {
  display: inline-table !important;
  vertical-align: middle;
}

.MJXp-mtr {
  display: table-row !important;
}

.MJXp-mtd {
  display: table-cell !important;
  text-align: center;
  padding: 0.5em 0 0 0.5em;
}

.MJXp-mtr > .MJXp-mtd:first-child {
  padding-left: 0;
}

.MJXp-mtr:first-child > .MJXp-mtd {
  padding-top: 0;
}

.MJXp-mlabeledtr {
  display: table-row !important;
}

.MJXp-mlabeledtr > .MJXp-mtd:first-child {
  padding-left: 0;
}

.MJXp-mlabeledtr:first-child > .MJXp-mtd {
  padding-top: 0;
}

.MJXp-merror {
  background-color: #FFFF88;
  color: #CC0000;
  border: 1px solid #CC0000;
  padding: 1px 3px;
  font-style: normal;
  font-size: 90%;
}

.MJXp-scale0 {
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
}

.MJXp-scale1 {
  -webkit-transform: scaleX(0.1);
  -moz-transform: scaleX(0.1);
  -ms-transform: scaleX(0.1);
  -o-transform: scaleX(0.1);
  transform: scaleX(0.1);
}

.MJXp-scale2 {
  -webkit-transform: scaleX(0.2);
  -moz-transform: scaleX(0.2);
  -ms-transform: scaleX(0.2);
  -o-transform: scaleX(0.2);
  transform: scaleX(0.2);
}

.MJXp-scale3 {
  -webkit-transform: scaleX(0.3);
  -moz-transform: scaleX(0.3);
  -ms-transform: scaleX(0.3);
  -o-transform: scaleX(0.3);
  transform: scaleX(0.3);
}

.MJXp-scale4 {
  -webkit-transform: scaleX(0.4);
  -moz-transform: scaleX(0.4);
  -ms-transform: scaleX(0.4);
  -o-transform: scaleX(0.4);
  transform: scaleX(0.4);
}

.MJXp-scale5 {
  -webkit-transform: scaleX(0.5);
  -moz-transform: scaleX(0.5);
  -ms-transform: scaleX(0.5);
  -o-transform: scaleX(0.5);
  transform: scaleX(0.5);
}

.MJXp-scale6 {
  -webkit-transform: scaleX(0.6);
  -moz-transform: scaleX(0.6);
  -ms-transform: scaleX(0.6);
  -o-transform: scaleX(0.6);
  transform: scaleX(0.6);
}

.MJXp-scale7 {
  -webkit-transform: scaleX(0.7);
  -moz-transform: scaleX(0.7);
  -ms-transform: scaleX(0.7);
  -o-transform: scaleX(0.7);
  transform: scaleX(0.7);
}

.MJXp-scale8 {
  -webkit-transform: scaleX(0.8);
  -moz-transform: scaleX(0.8);
  -ms-transform: scaleX(0.8);
  -o-transform: scaleX(0.8);
  transform: scaleX(0.8);
}

.MJXp-scale9 {
  -webkit-transform: scaleX(0.9);
  -moz-transform: scaleX(0.9);
  -ms-transform: scaleX(0.9);
  -o-transform: scaleX(0.9);
  transform: scaleX(0.9);
}

.mjx-chtml {
  display: inline-block;
  line-height: 0;
  text-indent: 0;
  text-align: left;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  font-size: 100%;
  font-size-adjust: none;
  letter-spacing: normal;
  word-wrap: normal;
  word-spacing: normal;
  white-space: nowrap;
  float: none;
  direction: ltr;
  max-width: none;
  max-height: none;
  min-width: 0;
  min-height: 0;
  border: 0;
  margin: 0;
  padding: 1px 0;
}

.MJXc-display {
  display: block;
  text-align: center;
  margin: 1em 0;
  padding: 0;
}

.mjx-chtml[tabindex]:focus,
body :focus .mjx-chtml[tabindex] {
  display: inline-table;
}

.mjx-full-width {
  text-align: center;
  display: table-cell !important;
  width: 10000em;
}

.mjx-math {
  display: inline-block;
  border-collapse: separate;
  border-spacing: 0;
}

.mjx-math * {
  display: inline-block;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
  text-align: left;
}

.mjx-numerator {
  display: block;
  text-align: center;
}

.mjx-denominator {
  display: block;
  text-align: center;
}

.MJXc-stacked {
  height: 0;
  position: relative;
}

.MJXc-stacked > * {
  position: absolute;
}

.MJXc-bevelled > * {
  display: inline-block;
}

.mjx-stack {
  display: inline-block;
}

.mjx-op {
  display: block;
}

.mjx-under {
  display: table-cell;
}

.mjx-over {
  display: block;
}

.mjx-over > * {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mjx-under > * {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mjx-stack > .mjx-sup {
  display: block;
}

.mjx-stack > .mjx-sub {
  display: block;
}

.mjx-prestack > .mjx-presup {
  display: block;
}

.mjx-prestack > .mjx-presub {
  display: block;
}

.mjx-delim-h > .mjx-char {
  display: inline-block;
}

.mjx-surd {
  vertical-align: top;
}

.mjx-mphantom * {
  visibility: hidden;
}

.mjx-merror {
  background-color: #FFFF88;
  color: #CC0000;
  border: 1px solid #CC0000;
  padding: 2px 3px;
  font-style: normal;
  font-size: 90%;
}

.mjx-annotation-xml {
  line-height: normal;
}

.mjx-menclose > svg {
  fill: none;
  stroke: currentColor;
}

.mjx-mtr {
  display: table-row;
}

.mjx-mlabeledtr {
  display: table-row;
}

.mjx-mtd {
  display: table-cell;
  text-align: center;
}

.mjx-label {
  display: table-row;
}

.mjx-box {
  display: inline-block;
}

.mjx-block {
  display: block;
}

.mjx-span {
  display: inline;
}

.mjx-char {
  display: block;
  white-space: pre;
}

.mjx-itable {
  display: inline-table;
  width: auto;
}

.mjx-row {
  display: table-row;
}

.mjx-cell {
  display: table-cell;
}

.mjx-table {
  display: table;
  width: 100%;
}

.mjx-line {
  display: block;
  height: 0;
}

.mjx-strut {
  width: 0;
  padding-top: 1em;
}

.mjx-vsize {
  width: 0;
}

.MJXc-space1 {
  margin-left: 0.167em;
}

.MJXc-space2 {
  margin-left: 0.222em;
}

.MJXc-space3 {
  margin-left: 0.278em;
}

.mjx-chartest {
  display: block;
  visibility: hidden;
  position: absolute;
  top: 0;
  line-height: normal;
  font-size: 500%;
}

.mjx-chartest .mjx-char {
  display: inline;
}

.mjx-chartest .mjx-box {
  padding-top: 1000px;
}

.MJXc-processing {
  visibility: hidden;
  position: fixed;
  width: 0;
  height: 0;
  overflow: hidden;
}

.MJXc-processed {
  display: none;
}

.mjx-test {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 100%;
  font-size-adjust: none;
  text-indent: 0;
  text-transform: none;
  letter-spacing: normal;
  word-spacing: normal;
  overflow: hidden;
  height: 1px;
}

.mjx-ex-box-test {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 60ex;
}

.mjx-line-box-test {
  display: table !important;
}

.mjx-line-box-test span {
  display: table-cell !important;
  width: 10000em !important;
  min-width: 0;
  max-width: none;
  padding: 0;
  border: 0;
  margin: 0;
}

#MathJax_CHTML_Tooltip {
  background-color: InfoBackground;
  color: InfoText;
  border: 1px solid black;
  box-shadow: 2px 2px 5px #AAAAAA;
  -webkit-box-shadow: 2px 2px 5px #AAAAAA;
  -moz-box-shadow: 2px 2px 5px #AAAAAA;
  -khtml-box-shadow: 2px 2px 5px #AAAAAA;
  padding: 3px 4px;
  z-index: 401;
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  display: none;
}

.MJXc-TeX-unknown-R {
  font-family: STIXGeneral, "Cambria Math", "Arial Unicode MS", serif;
  font-style: normal;
  font-weight: normal;
}

.MJXc-TeX-unknown-I {
  font-family: STIXGeneral, "Cambria Math", "Arial Unicode MS", serif;
  font-style: italic;
  font-weight: normal;
}

.MJXc-TeX-unknown-B {
  font-family: STIXGeneral, "Cambria Math", "Arial Unicode MS", serif;
  font-style: normal;
  font-weight: bold;
}

.MJXc-TeX-unknown-BI {
  font-family: STIXGeneral, "Cambria Math", "Arial Unicode MS", serif;
  font-style: italic;
  font-weight: bold;
}

.MJXc-TeX-ams-R {
  font-family: MJXc-TeX-ams-R, MJXc-TeX-ams-Rw;
}

.MJXc-TeX-cal-B {
  font-family: MJXc-TeX-cal-B, MJXc-TeX-cal-Bx, MJXc-TeX-cal-Bw;
}

.MJXc-TeX-frak-R {
  font-family: MJXc-TeX-frak-R, MJXc-TeX-frak-Rw;
}

.MJXc-TeX-frak-B {
  font-family: MJXc-TeX-frak-B, MJXc-TeX-frak-Bx, MJXc-TeX-frak-Bw;
}

.MJXc-TeX-math-BI {
  font-family: MJXc-TeX-math-BI, MJXc-TeX-math-BIx, MJXc-TeX-math-BIw;
}

.MJXc-TeX-sans-R {
  font-family: MJXc-TeX-sans-R, MJXc-TeX-sans-Rw;
}

.MJXc-TeX-sans-B {
  font-family: MJXc-TeX-sans-B, MJXc-TeX-sans-Bx, MJXc-TeX-sans-Bw;
}

.MJXc-TeX-sans-I {
  font-family: MJXc-TeX-sans-I, MJXc-TeX-sans-Ix, MJXc-TeX-sans-Iw;
}

.MJXc-TeX-script-R {
  font-family: MJXc-TeX-script-R, MJXc-TeX-script-Rw;
}

.MJXc-TeX-type-R {
  font-family: MJXc-TeX-type-R, MJXc-TeX-type-Rw;
}

.MJXc-TeX-cal-R {
  font-family: MJXc-TeX-cal-R, MJXc-TeX-cal-Rw;
}

.MJXc-TeX-main-B {
  font-family: MJXc-TeX-main-B, MJXc-TeX-main-Bx, MJXc-TeX-main-Bw;
}

.MJXc-TeX-main-I {
  font-family: MJXc-TeX-main-I, MJXc-TeX-main-Ix, MJXc-TeX-main-Iw;
}

.MJXc-TeX-main-R {
  font-family: MJXc-TeX-main-R, MJXc-TeX-main-Rw;
}

.MJXc-TeX-math-I {
  font-family: MJXc-TeX-math-I, MJXc-TeX-math-Ix, MJXc-TeX-math-Iw;
}

.MJXc-TeX-size1-R {
  font-family: MJXc-TeX-size1-R, MJXc-TeX-size1-Rw;
}

.MJXc-TeX-size2-R {
  font-family: MJXc-TeX-size2-R, MJXc-TeX-size2-Rw;
}

.MJXc-TeX-size3-R {
  font-family: MJXc-TeX-size3-R, MJXc-TeX-size3-Rw;
}

.MJXc-TeX-size4-R {
  font-family: MJXc-TeX-size4-R, MJXc-TeX-size4-Rw;
}

.MJXc-TeX-vec-R {
  font-family: MJXc-TeX-vec-R, MJXc-TeX-vec-Rw;
}

.MJXc-TeX-vec-B {
  font-family: MJXc-TeX-vec-B, MJXc-TeX-vec-Bx, MJXc-TeX-vec-Bw;
}

@font-face {
  font-family: MJXc-TeX-ams-R;
  src: local("MathJax_AMS"), local("MathJax_AMS-Regular");
}
@font-face {
  font-family: MJXc-TeX-ams-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_AMS-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_AMS-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_AMS-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-cal-B;
  src: local("MathJax_Caligraphic Bold"), local("MathJax_Caligraphic-Bold");
}
@font-face {
  font-family: MJXc-TeX-cal-Bx;
  src: local("MathJax_Caligraphic");
  font-weight: bold;
}
@font-face {
  font-family: MJXc-TeX-cal-Bw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Caligraphic-Bold.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Caligraphic-Bold.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Caligraphic-Bold.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-frak-R;
  src: local("MathJax_Fraktur"), local("MathJax_Fraktur-Regular");
}
@font-face {
  font-family: MJXc-TeX-frak-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Fraktur-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Fraktur-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Fraktur-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-frak-B;
  src: local("MathJax_Fraktur Bold"), local("MathJax_Fraktur-Bold");
}
@font-face {
  font-family: MJXc-TeX-frak-Bx;
  src: local("MathJax_Fraktur");
  font-weight: bold;
}
@font-face {
  font-family: MJXc-TeX-frak-Bw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Fraktur-Bold.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Fraktur-Bold.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Fraktur-Bold.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-math-BI;
  src: local("MathJax_Math BoldItalic"), local("MathJax_Math-BoldItalic");
}
@font-face {
  font-family: MJXc-TeX-math-BIx;
  src: local("MathJax_Math");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: MJXc-TeX-math-BIw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Math-BoldItalic.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Math-BoldItalic.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Math-BoldItalic.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-sans-R;
  src: local("MathJax_SansSerif"), local("MathJax_SansSerif-Regular");
}
@font-face {
  font-family: MJXc-TeX-sans-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_SansSerif-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_SansSerif-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_SansSerif-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-sans-B;
  src: local("MathJax_SansSerif Bold"), local("MathJax_SansSerif-Bold");
}
@font-face {
  font-family: MJXc-TeX-sans-Bx;
  src: local("MathJax_SansSerif");
  font-weight: bold;
}
@font-face {
  font-family: MJXc-TeX-sans-Bw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_SansSerif-Bold.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_SansSerif-Bold.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_SansSerif-Bold.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-sans-I;
  src: local("MathJax_SansSerif Italic"), local("MathJax_SansSerif-Italic");
}
@font-face {
  font-family: MJXc-TeX-sans-Ix;
  src: local("MathJax_SansSerif");
  font-style: italic;
}
@font-face {
  font-family: MJXc-TeX-sans-Iw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_SansSerif-Italic.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_SansSerif-Italic.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_SansSerif-Italic.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-script-R;
  src: local("MathJax_Script"), local("MathJax_Script-Regular");
}
@font-face {
  font-family: MJXc-TeX-script-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Script-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Script-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Script-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-type-R;
  src: local("MathJax_Typewriter"), local("MathJax_Typewriter-Regular");
}
@font-face {
  font-family: MJXc-TeX-type-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Typewriter-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Typewriter-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Typewriter-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-cal-R;
  src: local("MathJax_Caligraphic"), local("MathJax_Caligraphic-Regular");
}
@font-face {
  font-family: MJXc-TeX-cal-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Caligraphic-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Caligraphic-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Caligraphic-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-main-B;
  src: local("MathJax_Main Bold"), local("MathJax_Main-Bold");
}
@font-face {
  font-family: MJXc-TeX-main-Bx;
  src: local("MathJax_Main");
  font-weight: bold;
}
@font-face {
  font-family: MJXc-TeX-main-Bw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Main-Bold.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Main-Bold.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Main-Bold.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-main-I;
  src: local("MathJax_Main Italic"), local("MathJax_Main-Italic");
}
@font-face {
  font-family: MJXc-TeX-main-Ix;
  src: local("MathJax_Main");
  font-style: italic;
}
@font-face {
  font-family: MJXc-TeX-main-Iw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Main-Italic.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Main-Italic.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Main-Italic.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-main-R;
  src: local("MathJax_Main"), local("MathJax_Main-Regular");
}
@font-face {
  font-family: MJXc-TeX-main-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Main-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Main-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Main-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-math-I;
  src: local("MathJax_Math Italic"), local("MathJax_Math-Italic");
}
@font-face {
  font-family: MJXc-TeX-math-Ix;
  src: local("MathJax_Math");
  font-style: italic;
}
@font-face {
  font-family: MJXc-TeX-math-Iw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Math-Italic.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Math-Italic.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Math-Italic.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-size1-R;
  src: local("MathJax_Size1"), local("MathJax_Size1-Regular");
}
@font-face {
  font-family: MJXc-TeX-size1-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Size1-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Size1-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Size1-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-size2-R;
  src: local("MathJax_Size2"), local("MathJax_Size2-Regular");
}
@font-face {
  font-family: MJXc-TeX-size2-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Size2-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Size2-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Size2-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-size3-R;
  src: local("MathJax_Size3"), local("MathJax_Size3-Regular");
}
@font-face {
  font-family: MJXc-TeX-size3-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Size3-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Size3-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Size3-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-size4-R;
  src: local("MathJax_Size4"), local("MathJax_Size4-Regular");
}
@font-face {
  font-family: MJXc-TeX-size4-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Size4-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Size4-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Size4-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-vec-R;
  src: local("MathJax_Vector"), local("MathJax_Vector-Regular");
}
@font-face {
  font-family: MJXc-TeX-vec-Rw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Vector-Regular.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Vector-Regular.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Vector-Regular.otf") format("opentype");
}
@font-face {
  font-family: MJXc-TeX-vec-B;
  src: local("MathJax_Vector Bold"), local("MathJax_Vector-Bold");
}
@font-face {
  font-family: MJXc-TeX-vec-Bx;
  src: local("MathJax_Vector");
  font-weight: bold;
}
@font-face {
  font-family: MJXc-TeX-vec-Bw;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/eot/MathJax_Vector-Bold.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/woff/MathJax_Vector-Bold.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/fonts/HTML-CSS/TeX/otf/MathJax_Vector-Bold.otf") format("opentype");
}

