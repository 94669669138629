.flip-book {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  display: none;
  background-size: cover;
}

.page {
  padding: 10px;
  background-color: hsl(35, 55%, 98%);
  color: hsl(35, 35%, 35%);
  border: solid 1px hsl(35, 20%, 70%);
  overflow: hidden;
}
.page .page-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.page .page-content .page-header {
  height: 20px;
  font-size: 100%;
  text-transform: uppercase;
  text-align: center;
}
.page .page-content .page-image {
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.page .page-content .page-text {
  height: 100%;
  flex-grow: 1;
  font-size: 80%;
  text-align: justify;
  margin-top: 5px;
  padding-top: 5px;
  box-sizing: border-box;
  border-top: solid 1px hsl(35, 55%, 90%);
}
.page .page-content .page-footer {
  height: 20px;
  border-top: solid 1px hsl(35, 55%, 90%);
  font-size: 80%;
  color: hsl(35, 20%, 50%);
}
.page.--left {
  border-right: 0;
  box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
}
.page.--right {
  border-left: 0;
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
}
.page.--right .page-footer {
  text-align: right;
}
.page.hard {
  background-color: hsl(35, 50%, 90%);
  border: solid 1px hsl(35, 20%, 50%);
}
.page.page-cover {
  background-color: hsl(35, 45%, 80%);
  color: hsl(35, 35%, 35%);
  border: solid 1px hsl(35, 20%, 50%);
}
.page.page-cover h2 {
  text-align: center;
  padding-top: 50%;
  font-size: 210%;
}
.page.page-cover.page-cover-top {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
}
.page.page-cover.page-cover-bottom {
  box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), 10px 0 8px 0px rgba(0, 0, 0, 0.4);
}

